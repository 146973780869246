@import '~bootswatch/dist/superhero/bootstrap.css';
@import '~react-datetime/css/react-datetime.css';

em {
  background: #ff03;
}

.rdtPicker {
  background: #2b3e50;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: rgba(255, 255, 255, 0.2);
}

.modal-dialog {
  max-width: 900px;
}

.filterTools h2 {
  margin-top: 1em;
}

.filterTools h3 {
  margin-top: 0.5em;
}

.navbar-brand {
  color: rgb(235, 235, 235) !important;
}

.CardList{
  width:1000px;
}

.CardList .card {
  margin-bottom: 3em;
}

.card .date {
  position: absolute;
  right: 1.5em;
  top: 0.7em;
  display: block;
}

.card .title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 6.5em;
  display: block;
}

.hiddenLink,
.hiddenLink:hover {
  color: inherit;
  text-decoration: none;
}

a .card * {
  cursor: pointer;
}
