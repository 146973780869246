ul.filter {
  list-style-type: none;
  padding: 0;
  max-height: 250px;
  overflow-y: auto;
}

ul.filter label {
  font-size: 16px;
}
